import Image from 'next/image';
import { useState } from 'react';
import { Configure, InfiniteHits, InstantSearch } from 'react-instantsearch';

import {
  ActionCenterUserEventCardMobile,
  ActionCenterUserEventCardWeb,
  NoResultsBoundary,
  PageNotFound,
  TabMenu
} from '@/components/atomic/atoms';
import { CloseIcon, Text } from '@/components/atomic/nuclei';
import { DEVICE_SURFACE, staticMediaStoreBaseURL } from '@/config/common';
import {
  DEFAULT_HITS_PER_PAGE,
  DEFAULT_TAB_CATEGORY,
  SECTION_NAME,
  TAB_CATEGORY_CONFIG_LIST
} from '@/config/searchkit/actionCenter';
import { NAVIGATION_INDEX } from '@/config/searchkit/navigation';
import { GetUiDeviceSurface } from '@/lib/screenResolution';

const UserEventListByEventStageBody = ({
  selectedTabMenu,
  setSelectedTabMenu
}) => {
  const surface = GetUiDeviceSurface();

  const actionCenterUserEventCardWebMap = {
    [DEVICE_SURFACE.MOBILE]: ActionCenterUserEventCardMobile,
    [DEVICE_SURFACE.WEB]: ActionCenterUserEventCardWeb
  };

  const ActionCenterUserEventCard = actionCenterUserEventCardWebMap[surface];

  return (
    <div className='flex flex-col md:flex-row gap-4 w-full relative'>
      <TabMenu
        {...{
          attribute: 'stage',
          defaultTabCategory: DEFAULT_TAB_CATEGORY,
          containerMenuListStyle:
            '!left-0 border-r border-neutral pr-1 !mt-0 md:-mt-8 overflow-auto no-scrollbar !mx-0 md:mx-6',
          menuListStyle: 'flex md:flex-col gap-2',
          selectedTabMenu,
          setSelectedTabMenu,
          showTabMenuCount: true,
          tabContainerStyle: 'w-44 md:w-auto',
          tabCategoryConfigList: TAB_CATEGORY_CONFIG_LIST,
          tabLabelStyle: '',
          tabStyle:
            'navigate-cart hover:border hover:border-brand bg-white text-xs md:text-sm px-4 py-[10px] cursor-pointer font-medium flex rounded-lg border border-pink md:border-white flex-1'
        }}
      />
      {
        //TODO: add toggle MobileNavigatorTab on click on Image
      }
      <Image
        alt='tab icon'
        className='flex md:hidden absolute -right-[29px] -top-[7px] cursor-pointer'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/open-navigator-mobile-icon.svg`}
        style={{ width: 60, height: 60 }}
        width={0}
      />
      <div className='flex flex-col flex-1 gap-4 w-full'>
        <NoResultsBoundary
          fallback={
            <PageNotFound
              {...{
                className: 'pt-4',
                iconHeight: 320,
                iconWidth: 328,
                notFoundImage: 'dashboard-event-not-found.svg',
                title: `Awesome! You're all caught up`,
                titleStyle: 'font-light text-dim-gray mt-2'
              }}
            />
          }
        >
          <InfiniteHits
            {...{
              classNames: {
                item: 'p-0 border-none shadow-none w-full !block',
                list: 'flex flex-1 flex-col gap-2 md:gap-4 w-full',
                loadMore:
                  '!border-none !hover:bg-none !text-brand !font-medium searchkit-filter-show-more-button !bg-none !shadow-none  !pb-0 !h-3',
                root: 'flex flex-col flex-1 max-h-[30.5rem] overflow-auto no-scrollbar',
                showMore: 'searchkit-filter-show-more-button !pb-0 !h-3'
              },
              hitComponent: ({ hit }) => (
                <ActionCenterUserEventCard {...{ hit }} />
              ),
              showPrevious: false,
              translations: {
                showMoreButtonText: 'Show more'
              }
            }}
          />
        </NoResultsBoundary>
      </div>
    </div>
  );
};
{
  //TODO:  toggle MobileNavigatorTab on mobile
}
const MobileNavigatorTab = () => (
  <>
    <div class='searchkit-sort-overlayer overlayer left-0 top-0 z-20'></div>
    <div className='bg-white fixed bottom-0 left-0 right-0 h-[30rem] rounded-t-2xl z-50 p-5 block md:hidden'>
      <div className='flex md:hidden mx-auto bg-brand-gradient h-1 w-10 rounded-full -mt-3'></div>
      <div className='flex items-center justify-between'>
        <Text
          {...{
            className: 'text-base font-semibold text-nero',
            content: 'Events'
          }}
        />
        <CloseIcon />
      </div>
      <div className='mt-5'>
        <TabMenu
          {...{
            attribute: 'stage',
            defaultTabCategory: DEFAULT_TAB_CATEGORY,
            containerMenuListStyle:
              '!left-0 !mt-0 md:-mt-8 overflow-auto no-scrollbar !mx-0 md:mx-6',
            menuListStyle: 'flex flex-col gap-2',
            selectedTabMenu,
            setSelectedTabMenu,
            showTabMenuCount: true,
            tabContainerStyle: 'w-40 md:w-auto',
            tabCategoryConfigList: TAB_CATEGORY_CONFIG_LIST,
            tabLabelStyle: '',
            tabStyle:
              'navigate-cart hover:border hover:border-brand bg-white text-xs md:text-sm px-4 py-[10px] cursor-pointer font-medium flex rounded-lg border border-pink md:border-white flex-1 justify-center md:justify-start'
          }}
        />
      </div>
    </div>
  </>
);

const UserEventListByEventStage = ({ searchClient, selectedPlannerId }) => {
  const [selectedTabMenu, setSelectedTabMenu] = useState(DEFAULT_TAB_CATEGORY);
  const [isNavigatorOpen, setIsNavigatorOpen] = useState(false);
  return (
    <div className='my-4'>
      <Text
        {...{
          className: 'text-dim-gray font-semibold',
          content: 'See How Your Events Are Shaping Up'
        }}
      />
      <div className='flex gap-4 shadow-navigate-cart rounded-lg bg-white p-4 my-4'>
        <InstantSearch
          {...{
            future: {
              preserveSharedStateOnUnmount: true
            },
            indexName: NAVIGATION_INDEX,
            initialUiState: {
              [NAVIGATION_INDEX]: {
                menu: {
                  stage: DEFAULT_TAB_CATEGORY
                }
              }
            },
            searchClient
          }}
        >
          <Configure
            {...{
              hitsPerPage: DEFAULT_HITS_PER_PAGE,
              plannerId: selectedPlannerId,
              sectionName: SECTION_NAME.GLANCE_EVENT_PIPELINE
            }}
          />
          <UserEventListByEventStageBody
            {...{ selectedTabMenu, setSelectedTabMenu }}
          />
          {isNavigatorOpen && MobileNavigatorTab}
        </InstantSearch>
      </div>
    </div>
  );
};

export default UserEventListByEventStage;

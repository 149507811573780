import { PageNotFound } from '@/components/atomic/atoms';
import { NewAssignedUserEventCard } from '@/components/atomic/molecules';
import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { getQueryParameterForScopedUserEvents } from '@/services/actionCenter.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const openNewAssignedUserEvents = ({ selectedPlannerId }) =>
  window.open(
    getPageURL({
      pageName: PAGE_NAME.NEW_ASSIGNED_USER_EVENTS.label,
      queryParams: getQueryParameterForScopedUserEvents({
        selectedPlannerId
      })
    }),
    '_blank'
  );

const ViewAllCTA = ({ className = 'hidden', onClick = () => {} }) => (
  <TextWithIcon
    {...{
      className,
      iconSuffix: {
        alt: 'arrow icon',
        icon: 'angel-right-icon.svg',
        iconStyle: '',
        iconHeight: 10,
        iconWidth: 10
      },
      label: 'View All',
      labelStyle: 'text-sm text-dim-gray font-medium',
      onClick
    }}
  />
);

const NewAssignedUserEventOrganism = ({
  selectedPlannerId,
  upcomingAssignedUserEventsInfo
}) => (
  <div className='flex flex-col gap-1 flex-1 bg-white shadow-navigate-cart rounded-lg navigate-cart p-3  overflow-auto no-scrollbar h-52 md:h-auto'>
    <div className='flex justify-between items-center'>
      <div className='flex flex-col'>
        <TextKeyValuePair
          {...{
            className: 'flex-row items-center',
            label: 'New Events Assigned',
            labelClass: 'text-xl md:text-3xl font-semibold text-dim-gray',
            value:
              upcomingAssignedUserEventsInfo.upcomingAssignedUserEventsCount,
            valueClassName:
              'bg-brand-gradient text-white rounded-full min-w-6 h-6 items-center text-xs md:text-sm font-medium px-1 justify-center flex'
          }}
        />
        <Text
          {...{
            className: 'text-silver font-semibold text-xs md:text-base',
            content: `Let's Get These Events Moving!`
          }}
        />
      </div>
      {upcomingAssignedUserEventsInfo.upcomingAssignedUserEventsCount > 0 && (
        <ViewAllCTA
          {...{
            className:
              'cursor-pointer shadow-card py-1 px-2 rounded mt-7 hidden md:flex',
            onClick: () => openNewAssignedUserEvents({ selectedPlannerId })
          }}
        />
      )}
    </div>
    <div className='flex gap-4 md:mt-4 mt-2 overflow-auto no-scrollbar'>
      {upcomingAssignedUserEventsInfo.upcomingAssignedUserEvents?.length > 0 ? (
        upcomingAssignedUserEventsInfo.upcomingAssignedUserEvents.map(
          (upcomingUserEventDetail, index) => {
            const showOverlay = index === 9;
            return (
              <div
                key={index}
                className='relative'
              >
                <NewAssignedUserEventCard
                  key={index}
                  {...{
                    upcomingUserEventDetail,
                    onClickCard: ({ action = '' }) =>
                      window.open(
                        getPageURL({
                          pageName: PAGE_NAME.NEW_ASSIGNED_USER_EVENTS.label,
                          queryParams: {
                            action,
                            userEventId: upcomingUserEventDetail.userEventId,
                            ...getQueryParameterForScopedUserEvents({
                              selectedPlannerId
                            })
                          }
                        }),
                        '_blank'
                      )
                  }}
                />
                {showOverlay && (
                  <div className='absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center'>
                    <Text
                      {...{
                        className: 'font-semibold text-white cursor-pointer',
                        content: 'View All',
                        onClick: () =>
                          openNewAssignedUserEvents({
                            selectedPlannerId
                          })
                      }}
                    />
                  </div>
                )}
              </div>
            );
          }
        )
      ) : (
        <PageNotFound
          {...{
            className: 'pt-4 mx-auto',
            iconHeight: 114,
            iconWidth: 120,
            notFoundImage: 'dashboard-event-not-found.svg',
            title: `Awesome! You're all caught up`,
            titleStyle: 'font-light text-dim-gray mt-2'
          }}
        />
      )}
    </div>
  </div>
);

export default NewAssignedUserEventOrganism;

import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  EventProfileStep,
  StepFormHeader,
  TerminateEventStep,
  ViewEditCTAPair
} from '@/components/atomic/atoms';
import {
  AddressModal,
  EventDeepDiveStep,
  HostProfileStep
} from '@/components/atomic/molecules';
import { HOST_NAME_VALIDATION_CONFIG } from '@/config/common';
import useYupValidator from '@/hooks/yupValidator';
import {
  EVENT_PROFILE_FORM_STEPS,
  eventProfileStepFieldErrorConfig,
  submitUserEventProfile,
  userAddressObject
} from '@/services/hostEvent.service';

const { nameRegex, nameInputError } = HOST_NAME_VALIDATION_CONFIG;

const HostEventProfileOrganism = ({
  checkoutEvents,
  cityList,
  contactMethods,
  eventSiteTypeList,
  eventVerticals,
  leadExitReasons,
  setUserEventInformation,
  userEventInformation,
  userInfo
}) => {
  const router = useRouter();
  const {
    contactMethodId,
    leadExitReason,
    userEventDetails: [userEventDetails],
    zendeskTicketDetail = {},
    zendeskTicketId
  } = userEventInformation;
  const {
    budget,
    eventAddress,
    eventDate,
    eventSiteTypeId,
    eventStartTime,
    eventVerticals: selectedEventVerticals,
    expectedGuestCount,
    opportunityValue,
    opsUser
  } = userEventDetails;

  const [isUserEventDetailInEditMode, setUserEventDetailEditMode] =
    useState(false);
  const [formActiveStepId, setFormActiveStepId] = useState(
    EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id
  );

  const [eventFormDetails, setEventFormDetails] = useState({
    checkoutEvents,
    cityList,
    contactMethods,
    eventSiteTypeList,
    eventVerticals,
    opsUsersList: opsUser?.name ?? '',
    selectedEventContactMethod: contactMethodId,
    selectedEventDate: eventDate ? new Date(eventDate) : null,
    selectedEventSiteType: eventSiteTypeId,
    selectedEventStartTime: eventStartTime?.slice(0, 5) || '',
    showAddAddress: false,
    showAddressError: false,
    toastMessage: '',
    toastStatus: false,
    userAddress: eventAddress || userAddressObject,
    userEventId: userEventInformation?.id,
    zendeskTicketId: zendeskTicketId ?? ''
  });

  const [selectedEventRequestVerticals, setSelectedEventRequestVerticals] =
    useState(selectedEventVerticals.map(({ id }) => id));

  const transformNumberValue = (key, val) => {
    const parsedValue = parseInt(val, 10);
    return Number.isNaN(parsedValue) ? null : parsedValue;
  };

  const {
    clearErrors,
    control,
    formState: { errors },
    setError,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue
  } = useForm({
    resolver: useYupValidator(
      yup.object().shape({
        eventContactName: yup
          .string()
          .transform((key, val) => val || '')
          .trim()
          .matches(nameRegex, nameInputError)
          .required('Contact Name is mandatory'),
        eventContactEmail: yup
          .string()
          .trim()
          .transform((key, val) => (val ? val.toLowerCase() : ''))
          .email()
          .required('Contact Email is required'),
        // eslint-disable-next-line newline-per-chained-call
        eventTitle: yup.string().trim().nullable().notRequired(),
        opportunityValue: yup
          .number()
          .positive()
          .transform(transformNumberValue)
          .nullable(true)
          .notRequired(),
        budget: yup
          .number()
          .positive()
          .nullable(true)
          .notRequired()
          .transform(transformNumberValue),
        expectedGuestCount: yup
          .number()
          .nullable(true)
          .notRequired()
          .transform(transformNumberValue),
        eventDurationInDays: yup
          .number()
          .positive()
          .transform(transformNumberValue)
          .min(1, 'Event Duration should be a minimum 1 (in days)')
          .required('Event Duration is required.'),
        checkoutEventId: yup
          .string()
          .trim()
          .required('Event Type is mandatory'),
        // eslint-disable-next-line newline-per-chained-call
        eventDate: yup.string().trim().required('Event date is mandatory')
      })
    ),
    defaultValues: {
      eventContactEmail: userInfo.email || '',
      eventContactName: userInfo.name || '',
      ...(userEventDetails || {}),
      ...zendeskTicketDetail,
      ...(userEventInformation?.user || {}),
      budget: budget || '',
      eventDurationInDays: userEventInformation?.eventDurationInDays,
      expectedGuestCount,
      leadExitReasonId: leadExitReason?.id || null,
      opportunityValue: opportunityValue || '',
      zendeskTicketId
    }
  });

  const addAddressToggler = () =>
    setEventFormDetails((prevState) => ({
      ...prevState,
      showAddAddress: !prevState.showAddAddress
    }));

  const onExitClick = () => {
    router.back();
  };

  const onCancelClick = () => {
    reset();
    setEventFormDetails((prevState) => ({
      ...prevState,
      selectedEventContactMethod: contactMethodId,
      selectedEventSiteType: eventSiteTypeId
    }));
    setSelectedEventRequestVerticals(
      selectedEventVerticals.map(({ id }) => id)
    );
    setUserEventDetailEditMode(false);
  };

  useEffect(() => {
    const isToastMessageAvailable = Boolean(eventFormDetails.toastMessage);

    isToastMessageAvailable &&
      setTimeout(
        () =>
          setEventFormDetails((prevState) => ({
            ...prevState,
            toastMessage: ''
          })),
        15000
      );
  }, [eventFormDetails.toastMessage]);

  const onUpdateUserEventProfile = () =>
    handleSubmit((data) =>
      submitUserEventProfile({
        data,
        eventFormDetails,
        reset,
        selectedEventRequestVerticals,
        setError,
        setEventFormDetails,
        setFormActiveStepId,
        setUserEventDetailEditMode,
        setUserEventInformation,
        setValue,
        userInfo
      })
    );

  const onSubmitHandler = isUserEventDetailInEditMode
    ? onUpdateUserEventProfile()
    : () => setUserEventDetailEditMode(true);

  const notificationConfig = {
    errors,
    toastConfig: {
      message: eventFormDetails.toastMessage,
      show: Boolean(eventFormDetails.toastMessage) || !_.isEmpty(errors),
      successToast: eventFormDetails.toastStatus
    }
  };

  const modeType = isUserEventDetailInEditMode ? 'editCTAPair' : 'viewCTAPair';

  return (
    <div className='bg-white mt-0 mb-6 pl-6 pb-10'>
      <ViewEditCTAPair
        {...{
          backward: {
            onClick: () =>
              isUserEventDetailInEditMode ? onCancelClick() : onExitClick()
          },
          forward: {
            show: true,
            onClick: onSubmitHandler
          },
          notificationConfig,
          type: modeType
        }}
      />
      <div className='w-full mt-4 md:mt-0'>
        <div className='w-full rounded-2.5 mb-20'>
          <StepFormHeader
            {...{
              errors,
              fieldsErrorConfig: eventProfileStepFieldErrorConfig,
              formActiveStepId,
              onExitClick,
              setFormActiveStepId,
              showCloseIcon: !isUserEventDetailInEditMode,
              tabList: Object.values(EVENT_PROFILE_FORM_STEPS)
            }}
          />

          <div className='px-6'>
            <EventProfileStep
              {...{
                clearErrors,
                control,
                errors,
                eventFormDetails,
                eventVerticals,
                formActiveStepId,
                getValues,
                isUserEventDetailInEditMode,
                register,
                selectedEventRequestVerticals,
                setEventFormDetails,
                setSelectedEventRequestVerticals,
                setValue
              }}
            />
            <HostProfileStep
              {...{
                errors,
                formActiveStepId,
                getValues,
                isUserEventDetailInEditMode,
                register
              }}
            />
            <EventDeepDiveStep
              {...{
                contactMethods: eventFormDetails.contactMethods,
                errors,
                eventFormDetails,
                eventSiteTypeList,
                formActiveStepId,
                getValues,
                isUserEventDetailInEditMode,
                register,
                selectedEventContactMethod:
                  eventFormDetails.selectedEventContactMethod,
                setEventFormDetails,
                setValue
              }}
            />
            <TerminateEventStep
              {...{
                clearErrors,
                control,
                errors,
                formActiveStepId,
                getValues,
                isUserEventDetailInEditMode,
                leadExitReasons,
                register,
                setValue
              }}
            />
          </div>
        </div>
      </div>
      {
        // TODO: Hardik -- move this inside Event Deep Dive FormStep.
      }
      {eventFormDetails.showAddAddress && (
        <AddressModal
          {...{
            addAddressToggler,
            cityList: eventFormDetails.cityList,
            handleSubmit,
            setShowAddressError: (addressError) =>
              setEventFormDetails((prevState) => ({
                ...prevState,
                showAddressError: addressError
              })),
            setUserAddress: (address) =>
              setEventFormDetails((prevState) => ({
                ...prevState,
                userAddress: address
              })),
            userAddress: eventFormDetails.userAddress
          }}
        />
      )}
    </div>
  );
};

export default HostEventProfileOrganism;
